<template>
    <div>
        <div v-if="authUserPermission['characteristic-master-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('characteristic_master') }}</h3>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_characteristic_master')" @keyup="filter"/>                            
                                </div>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="create" v-if="authUserPermission['characteristic-master-create']">{{ tt('add_characteristic') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('characteristics')" :prop="tt('characteristics')" min-width="250px" sortable>
                                <template v-slot="{row}">
                                    {{row.characteristic}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('char_sap')" :prop="tt('char_sap')" min-width="250px" sortable>
                                <template v-slot="{row}">
                                    {{row.char_sap}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('type')" :prop="tt('type')" min-width="105px" sortable>
                                <template v-slot="{row}">
                                    {{row.item_type_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('single_multiple')" :prop="tt('single_multiple')" min-width="180px" sortable>
                                <template v-slot="{row}">
                                    <span v-if="row.single_or_multiple == 1">SINGLE</span>
                                    <span v-else-if="row.single_or_multiple == 2">MULTIPLE</span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('label')" :prop="tt('label')" min-width="144px" sortable>
                                <template v-slot="{row}">
                                    {{row.label}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('position_of_label')" :prop="tt('position_of_label')" min-width="241px" sortable>
                                <template v-slot="{row}">
                                    {{row.position}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('space')" :prop="tt('space')" min-width="110px" sortable>
                                <template v-slot="{row}">
                                    {{row.space}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['characteristic-master-update'] || authUserPermission['characteristic-master-delete']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['characteristic-master-update']">{{ tt('edit') }}</el-dropdown-item>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['characteristic-master-delete']">{{ tt('delete') }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <skeleton-loading v-else/>
                    </div>      
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>
                        
                        <label class="form-control-label">{{ tt('characteristic') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('characteristic')" :placeholder="tt('characteristic')" v-model="characteristicMaster.characteristic" rules="required" @input="characteristicMaster.characteristic = characteristicMaster.characteristic.toUpperCase()"></base-input>

                        <label class="form-control-label">{{ tt('char_sap') }} (24) <span class="text-danger">*</span></label>
                        <base-input :name="tt('char_sap')" :placeholder="tt('char_sap')" v-model="characteristicMaster.char_sap" rules="required|max:24" @input="characteristicMaster.char_sap = characteristicMaster.char_sap.replace(/\s+/g, '_').toUpperCase()"></base-input>

                        <label class="form-control-label">{{ tt('single_multiple') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('single_multiple')" rules="required">
                            <el-select class="select-danger" v-model="characteristicMaster.single_or_multiple" :placeholder="tt('choose_single_multiple')">
                                <el-option class="select-danger" value="1" label="Single" key="Single"></el-option>
                                <el-option class="select-danger" value="2" label="Multiple" key="Multiple"></el-option>
                            </el-select>
                        </base-input>

                        <div class="row">
                            <div class="col-6">
                                <label class="form-control-label">{{ tt('label') }}</label>
                                <base-input  :name="tt('label')" :placeholder="tt('label')" v-model="characteristicMaster.label" @input="characteristicMaster.label = characteristicMaster.label.toUpperCase()"></base-input>
                            </div>
                            <div class="col-6">
                                <label class="form-control-label">{{ tt('position') }}</label>
                                <base-input :name="tt('position')">
                                    <el-select class="select-danger" v-model="characteristicMaster.position" :placeholder="tt('choose_position')">
                                        <el-option class="select-danger" value="BEFORE" label="Before" key="Before"></el-option>
                                        <el-option class="select-danger" value="AFTER" label="After" key="After"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <label class="form-control-label">{{ tt('space') }}</label>
                                <base-input :name="tt('space')">
                                    <el-select class="select-danger" v-model="characteristicMaster.space" :placeholder="tt('choose_space')">
                                        <el-option class="select-danger" value="YES" label="Yes" key="Yes"></el-option>
                                        <el-option class="select-danger" value="NO" label="No" key="No"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div class="col-6">
                                <label class="form-control-label">{{ tt('type') }} <span class="text-danger">*</span></label>
                                <base-input :name="tt('type')" rules="required">
                                    <el-select class="select-danger" v-model="characteristicMaster.type_code" :placeholder="tt('choose_holding')">
                                        <el-option class="select-danger" :value="it['code']" :label="it['description']" :key="it['description']" v-for="it in  itemType"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                        </div>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import characteristicMaster from '@/services/dictionary/characteristicMaster.service';

    export default {        
        data() {
            return {      
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },            
                form: {
                    title: this.tt('add_characteristic_master'),
                    show: false
                },
                table: {
                    search: (this.$route.query.search) ? this.$route.query.search : '',                      
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },     
                errorMessage: null,   
                characteristicMaster: {},
                itemType: {},
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            this.getItemType()
        },
        methods: {
            get() { 
                let context = this;

                Api(context, characteristicMaster.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;
                    context.table.page  = response.data.data.data;   
                    context.onLoad = false;          
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            getItemType() {
                let context = this;               
                Api(context, characteristicMaster.item_type({per_page: 'none'})).onSuccess(function(response) { 
                    context.itemType = response.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.itemType = [];
                    }
                })
                .call()
            },
            filter() {
                if(this.table.search != this.$route.query.search){
                    this.onLoad = true;
                    this.table.page.current_page = 1;
                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(this.table.search == ''){
                            this.$router.push({path:'/dictionary/characteristic-master', query:null})
                        }else{
                            this.$router.push({ path: '/dictionary/characteristic-master', query:{search: this.table.search}})
                        }
                        this.get()
                    }, 300);
                }
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_characteristic_master');
                this.form.show = true;
                this.characteristicMaster = {
                    'characteristic': '',
                    'char_sap': '',
                    'label': '',
                    'position': '',
                    'space': '',
                    'type_code': '',
                    'single_or_multiple': '',
                };
            },            
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, characteristicMaster.show(id)).onSuccess(function(response) {
                    context.characteristicMaster = response.data.data;
                    context.form.add = false;
                    context.form.title = context.tt('edit_characteristic_master');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;

                // context.get();

                if (this.form.add) {
                    api = Api(context, characteristicMaster.create(this.characteristicMaster));
                } else {{
                    api = Api(context, characteristicMaster.update(this.characteristicMaster.id, this.characteristicMaster));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, characteristicMaster.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
        }   
    };
</script>
<style></style>
